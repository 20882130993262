@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,500;0,600;0,700;0,800;1,100;1,200;1,400;1,500;1,600;1,800&display=swap');
.App {
  background: color #f5f6f7;
  
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  }
  body {
    
    font-family: 'Poppins', sans-serif;
  }
  a {
    text-decoration: none;
    color: #1f1f1f;
  }
  li {
    list-style: none;
  }
  .container {
    max-width: 90%;
    margin: auto;
  }
  .d_flex {
    display: flex;
    justify-content: space-between;
  }
  .c_flex{
    display: flex;
    justify-content: space-between;
  }
  .f_flex {
    display: flex;


  }
  .capitalize{
    text-transform: capitalize;
  }
  .RText {
    text-align: right;
  }
  .uppercase {
    text-transform: uppercase;
  }
input ,button{
  border: none;
  outline: none;
  background-color: none;
}
.row {
  width: 50%;
}
.icon-circle {
  width: 50px;
  height: 50px;
  line-height: 50px;
 background-color: #f3f5f9 ;
  text-align: center;
  padding-left: 20px;
  border-radius: 50%;
  display: flex;

}
.boxShadow{
  box-shadow: rgba(42, 52, 69, 0.1) 0 4px 16px;
}
.contentWith{
  width: 77%;
}
.category {
  width: 23%;
  box-shadow: rgba(42, 52, 69, 0.1) 0px 4px 16px;
  height: 610px;
  padding: 15px 0px;
  margin-top: 5px;
  border-radius: 5px;
}
.category img{
  width: 30px;
  height: 30px;
  margin-top: 5px;
}
.category span{
  margin: 10px 20px;
  text-transform: capitalize;
}
.category .box{
  transition: 0.5s;
  padding: 0 20px;

}
.category .box:hover{
  cursor: pointer;
  color: #e92b84;
}
.homeSlide .top {
  z-index: 10000;
}
.box .d_flex .top{
  z-index: 10000;
}